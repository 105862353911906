<template>
    <div>

    </div>
</template>

<script>
// import { saveAs } from 'file-saver'
import { mapState } from 'vuex'
export default {
    name: "AppLink",
    data() {
        return {
          isWechat: false,
          isIos: false
        }
    },
    methods: {

    },
    computed: {
      ...mapState({
        apkUrl: state => state.apkUrl,
      })
    },
    mounted() {
      const userAgent = navigator.userAgent.toLowerCase()
      const match = userAgent.match(/MicroMessenger/i)
      if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        window.location.href = 'https://www.mianjuyuehui.net/webs/apple-down'
      } else {
        // window.location.href = 'https://www.mianjuyuehui.net/download/mianjuyuehui.apk'
        if (match !== null && match.toString() === 'micromessenger') {
          window.location.href = 'https://www.mianjuyuehui.net/webs/down-page'
        } else {
          // saveAs(this.apkUrl, 'mianjuyuehui.apk')
          window.location.href = "maskdating://launch"
          setTimeout(() => {
            window.location.href = 'https://www.mianjuyuehui.net/webs/down-page'
          }, 500)
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.tuiguang {
    height: 100%;
    position: relative;
    &-bg {
        min-width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &-content {
        width: 1000px;
        height: 671px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        &-phone {
            width: 336px;
            height: 671px;
            transform: scale(0.8);
            position: relative;
            > img {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &-ctn {
                width: 320px;
                height: 640px;
                border-radius: 50px;
            }
            &-frame {
                width: 100%;
                height: 100%;
            }
        }
        &-qrcode {
            width: 126px;
            height: 126px;
            border-radius: 10px;
            box-sizing: border-box;
            border: 1px solid white;
            display: flex;
            align-items: center;
            justify-content: center;
            > img {
                width: 106px;
                height: 106px;
            }
        }
        &-btn {
            width: 200px;
            height: 48px;
            background: linear-gradient(270deg, #C479DE 0%, #7456CB 100%);
            box-shadow: 0px 4px 6px 0px rgba(121, 86, 203, 0.2);
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
            >img {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }
        &-title {
            font-size: 42px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #FFFFFF;
        }
        &-subtitle {
            margin-top: 40px;
            font-size: 34px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #FFFFFF;
        }
    }
    &-icon {
        width: 150px;
        height: 60px;
        position: absolute;
        top: 40px;
        left: 200px;
        transform: scale(0.8);
    }
    &-beian {
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        color: white;
        font-size: 13px;
        &-url {
            color: #f18989;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>
